<template>
  <div class="box">
    <div class="content">
<!--      <div class="banner"></div>-->
      <div class="main">
        <div class="lunbo">
          <el-carousel :interval="3000" arrow="always" height="12rem" indicator-position="none">
            <el-carousel-item v-for="(item,index) in bannerList" :key="index">
              <div class="title">{{item.journalisTitle}}</div>
              <el-image class="img" :src="echoImg(item.bannerUrl)" fit="fit" @click="goDetails('',item.id)"></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
        <template v-for="(item,index) in subjectList" @key="index">
          <ul class="ul" :class="'ul_'+index" v-if="index<=5" :key="index">
            <li :class="'li_'+index">
              <div class="titleBox">
<!--                <img class="icon" src="../images/handball-people1.png"/>-->
                <span class="title1" :style="'color:'+color">{{item.navigationName}}</span>
                <span class="more" :style="'background:'+color" @click="more(item.navigationName,item.id,item.navigationType)">更多 +</span>
              </div>
              <ul :class="index==0?'ulBg':''">
                <template v-for="(dates,idxs) in item.journalism" @key="idxs">
                  <li v-if="index==4?idxs<6:index==5?idxs<6:idxs<8" @click="goDetails(item.navigationName, dates.id)" :key="idxs" class="theme">
                    <div class="imgDiv" v-if="index==5">
                      <div class="imgBox">
                        <img :src="echoImg(dates.coverImg)"/>
                        <span>{{toDate(dates.createTime,"yyyy-MM-dd")}}</span>
                      </div>
                      <div class="mapTitle">{{dates.journalisTitle}}</div>
                    </div>
                    <div class="newTitle" v-else>
                      <img class="coverImg" v-if="index==4" :src="echoImg(dates.coverImg)"/>
<!--                      <span class="span3" v-if="index==0" :style="{background:color}">{{idxs+1}}</span>-->
                      <span class="span1">{{dates.journalisTitle}}</span>
                      <span class="span2">{{toDate(dates.createTime,"yyyy-MM-dd")}}</span>
                    </div>
                  </li>
                </template>
              </ul>
            </li>
          </ul>
        </template>
        <ul class="partners">
          <div class="titleBox titleBox1">
<!--            <img class="icon" src="../images/handball-people1.png"/>-->
            <span class="title1" :style="'color:'+color">友情链接</span>
          </div>
          <div class="containt">
            <ul :style="{ left: calleft + 'px', width: widthData + 'px' }" v-on:mouseover="stopMove()" v-on:mouseout="move()" class="imgBoxoul">
              <li v-for="(item, index) in linksList2" :key="index" ref="lis">
                <div class="lbTitle" @click="openLinks(item.linkUrl)">{{item.linkName}}</div>
<!--                <el-image class="img" :src="echoImg(item.imgUrl)" @click="openLinks(item.linkUrl)" fit="fit"></el-image>-->
              </li>
              <li v-for="(dates, idx) in linksList2" :key="'c_' + idx" ref="lis1">
                <div class="lbTitle" @click="openLinks(dates.linkUrl)" >{{dates.linkName}}</div>
<!--                <el-image class="img" :src="echoImg(dates.imgUrl)" @click="openLinks(dates.linkUrl)" fit="fit"></el-image>-->
              </li>
            </ul>
          </div>
        </ul>
      </div>
    </div>
    <div class="fixedBox" v-if="isShowBtn">
      <div class="iconBox" id="iconBox" @click="showUl">
        <i class="icon" :class="isShowUl?'el-icon-d-arrow-left':'el-icon-d-arrow-right'"></i>
      </div>
      <transition key="2">
        <ul class="ul1" v-show="!isShowUl">
          <li @click="myRegistration">我的注册</li>
          <li v-for="(item,index) in fixedBoxList" :key="index" @click="registrationBtn(item.infoCategory)">{{item.name}}</li>
        </ul>
      </transition>
    </div>
  </div>
</template>
<script>
import { selectLink,selectMapDepot,selectBanner,selectListApp,getTextarea } from "@/api/template";
// import AboutUs from "./aboutUs";
import { mapState } from "vuex";
import {baseInfoPersonnelFormPage} from "../../../api/template";
export default {
  name: "Index",
  // components: { AboutUs },
  data() {
    return {
      isShowUl:true,
      isShowBtn:false,
      fixedBoxList:[],
      linksList: [],
      mapDepotList: [],
      linksList1:[],
      linksList2:[],
      bannerList: [],
      subjectList: [],
      synopsis:'',
      introduce:'',
      calleft: 0,
      speed: 1,
      timer: '',
      lyList:['../images/gg1.jpg','../images/gg2.jpg'],
    };
  },
  created(){
    this.init()
    this.move()
    // console.log(this.color,5678)
  },
  computed: {
    widthData(){
      return 150 * Number(this.linksList2.length * 2)
    },
    ...mapState(["color"]),
  },
  mounted() {
    // let imgBox = document.getElementsByClassName("imgBoxoul")[0];
    // imgBox.innerHTML += imgBox.innerHTML;
  },
  methods: {
    init(){
      this.getSelectBanner()
      this.getSelectList()
      this.getSelectMapDepot()
      this.getSelectLink()
      if(window.localStorage.getItem("token")){
        this.getBaseInfoPersonnelFormPage()
      }
    },
    getSelectLink(){
      let self = this
      selectLink().then(res =>{
        if(res.code == 200){
          res.data.forEach(item =>{
            if(!item.imgUrl){
              this.linksList1.push(item)
            }else if(item.imgUrl){
              this.linksList2.push(item)
            }
          })
          this.linksList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectBanner(){
      let self = this
      selectBanner().then(res =>{
        if(res.code == 200){
          this.bannerList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectMapDepot(){
      let self = this
      selectMapDepot().then(res =>{
        if(res.code == 200){
          this.mapDepotList = res.data
          console.log(this.mapDepotList)
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectList(){
      let self = this
      selectListApp().then(res =>{
        if(res.code == 200){
          this.subjectList = res.data
          // this.subjectList = res.data.reverse()
          // console.log(this.subjectList)
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    openLinks(linkUrl){
      window.open(linkUrl,'_blank')
    },
    more(navigationName,id,type){
      this.$router.push({params: {name:navigationName,id:id,type: type},name:'tenthMobile-list'})
    },
    goDetails(navigationName,id) {
      let data = {
        name: '',
        id: id,
      }
      window.sessionStorage.setItem('detailsValue', JSON.stringify(data))
      this.$router.push('/tenthMobile-details')
    },
    getTextareaFun(journalisContent,type){
      let params = {
        "journalisContent":journalisContent
      }
      getTextarea(params).then(res =>{
        if(type == 'synopsis'){
          this.synopsis = res.data.dataHtml
        }
        if(type == 'introduce'){
          this.introduce = res.data.dataHtml
        }
      })
    },
    move() {
      this.timer = setInterval(this.starMove, 20);
    },
    //开始移动
    starMove() {
      this.calleft -= 1.2;//*this.speed
      if (this.calleft <= -this.linksList2.length*191) {
        this.calleft = 0;
      }
    },
    //鼠标悬停时停止移动
    stopMove() {
      clearInterval(this.timer);
    },
    changeActive(e){
      e.currentTarget.className = "active"
    },
    removeActive(e){
      e.currentTarget.className = ""
    },
    //获取人员类型模本
    getBaseInfoPersonnelFormPage(){
      let params = {
        currentPage:1,
        pageSize:20,
        organizeId:window.localStorage.getItem("domainTid"),
        infoCategory:''
      }
      baseInfoPersonnelFormPage(params).then(res=>{
        if(res.code == 200){
          this.isShowBtn = true
          this.fixedBoxList = res.data.list
        }
      })
    },
    registrationBtn(infoCategory){
      this.$router.push({params: {infoCategory:infoCategory},name:'tenthMobile-registration'})
    },
    myRegistration(){
      this.$router.push({name:'tenthMobile-myRegistration'})
    },
    showUl(){
      this.isShowUl = !this.isShowUl
      // document.querySelector('.iconBox').setAttribute("style", "right: 100px")
    }
  },
};
</script>
<style lang="scss" scoped>
  .content{
    position: relative;
    .banner{
      width: 100%;
      height: 450px;
      background: url("../images/bannerBg.jpg") no-repeat center center;
      background-size: 100% 100%;
      .img{
        width: 100%;
        height: 100%;
      }
    }
    .main{
      width: 23rem;
      background: #fff;
      margin: 1rem auto;
      padding: 0 .5rem .5rem;
      min-height: 10rem;
      overflow: hidden;
      .titleBox{
        position: relative;
        width: 100%;
        background: #f2f2f2;
        color: #666;
        font-size: 1rem;
        height: 1.5rem;
        line-height: 1.5rem;
        .icon{
          vertical-align: middle;
          border-style: none;
          margin-right: .5rem;
          margin-left: .5rem;
          width: 1rem;
          height: 1.2rem;
        }
        .title1{
          font-weight: bold;
          color: #e60012;
          margin-left: .5rem;
        }
        .more{
          display: inline-block;
          width: 4rem;
          text-align: center;
          position: absolute;
          right: 0;
          top: 0;
          height: 1.5rem;
          line-height: 1.5rem;
          background: #e60012;
          color: #fff;
          font-size: 1rem;
          cursor: pointer;
        }
      }
      .titleBox1{
        text-align: center;
        height: 2rem;
        line-height: 2rem;
      }
      .lunbo{
        position: relative;
        width: 23rem;
        height: 12rem;
        display: inline-block;
        cursor: pointer;
        margin-top: 20px;
        .title{
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          background: rgba(0,0,0,0.6);
          z-index: 99;
          color: #fff;
          height: 40px;
          line-height: 40px;
          padding: 0 20px;
        }
        .img{
          width: 100%;
        }
        /deep/ .el-carousel__arrow{
          background-color:rgba(31,45,61,.4);
        }
        /deep/.el-carousel__indicators{
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
      .ul{
        display: inline-block;
        vertical-align: top;
        margin-top: .5rem;
        .li_0{
          display: inline-block;
          width: 23rem;
          //margin-left: .5rem;
          cursor: pointer;
          .ulBg{
            width: 23rem;
            //background: #f7f7f7;
            //padding: .2rem;
          }
          .newTitle{
            height: 1.2rem;
            line-height: 1.2rem;
            margin-top: .5rem;
            .span3{
              float: left;
              width: 5%;
              background: #ed5d52;
              color: #fff;
              text-align: center;
              margin-right: 2%;
            }
            .span1{
              float: left;
              width: 65%;
              line-height: 1rem;
              //font-weight: bold;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .span2{
              float: right;
              width: 30%;
              text-align: right;
            }
          }
        }
        .li_1,.li_2,.li_3{
          display: inline-block;
          width: 23rem;
          vertical-align: top;
          cursor: pointer;
          //margin-right: .5rem;
          .newTitle{
            width: 23rem;
            height: 1.2rem;
            line-height: 1.2rem;
            margin-top: .5rem;
            //padding: 0 .5rem;
            ul{
              width: 22rem;
              padding: .5rem;
            }
            .span1{
              float: left;
              width: 65%;
              height: 1rem;
              line-height: 1rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              vertical-align: middle;
            }
            .span2{
              float: right;
              width: 30%;
              text-align: right;
            }
          }
        }
        .li_3{
          margin-right: 0;
        }
      }
      .ul_4{
        width: 23rem;
        margin-top: .5rem;
        ul{
          li{
            display: inline-block;
            position: relative;
            width: 23rem;
            padding: .5rem 0;
            border-bottom: 1px solid #eee;
            //font-weight: bold;
            cursor: pointer;
            height: 4rem;
            .coverImg{
              position: absolute;
              left: .5rem;
              top: .5rem;
              width: 6rem;
              height: 4rem;
              margin-right: .5rem;
            }
            .span1{
              position: absolute;
              left: 7.5rem;
              top: 1rem;
              width: 15rem;
              height: 1.2rem;
              //font-size: 1rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            .span2{
              position: absolute;
              left: 7.5rem;
              bottom: 1rem;
              width: 15rem;
              //font-size: 1rem;
              //color: #999;
              //float: right;
            }
          }
          li:nth-child(odd){//奇数行
            margin-right: 25px;
          }
          li:nth-child(even){//偶数行
            margin-right: 0;
          }
        }
      }
      .ul_5{
        display: inline-block;
        width: 23rem;
        //margin-left: .5rem;
        margin-top: .5rem;
        .titleBox{
          margin-bottom: 1rem;
        }
        li{
          display: inline-block;
          width: 100%;
          cursor: pointer;
          ul{
            li{
              float: left;
              width: 11rem;
              margin-bottom: .5rem;
              .imgBox{
                position: relative;
                width: 11rem;
                height: 8rem;
                img{
                  width: 100%;
                  height: 100%;
                }
                span{
                  position: absolute;
                  bottom: 0;
                  background: rgba(0,0,0,.6);
                  color: #fff;
                  width: 100%;
                  height: 1rem;
                  //font-size: 1rem;
                  line-height: 1rem;
                  text-align: center;
                  left: 0;
                }
              }
              .mapTitle{
                width: 11rem;
                //font-size: 1rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1rem;
                margin-top: .5rem;
              }
            }
            li:nth-child(odd){//奇数行
              margin-right: 1rem;
            }
            li:nth-child(even){//偶数行
              margin-right: 0;
            }
          }
        }

      }
      .partners{
        width: 100%;
        margin-top: .5rem;
        .bTitle{
          height: 1.2rem;
          line-height: 1.2rem;
          background: #f2f2f2;
          font-size: 1rem;
          font-weight: bold;
          color: #e60012;
          padding-left: .5rem;
        }
        .containt {
          position: relative;
          //padding: 20px 0;
          overflow-y: auto;
          width: 100%;
          height: 2rem;
          overflow: hidden;
          margin: 0 auto;
          ul {
            width: 100%;
            //height: 5rem;
            position: absolute;
            left: 0px;
            cursor: pointer;
            z-index: 10;
            overflow: hidden;
            margin: .5rem auto;
            li {
              float: left;
              //height: 4rem;
              //padding: 30px 48px 0;
              overflow: hidden;
              width: 150px;
              text-align: center;
              border-right: 1px solid #eee;
              padding: 0 20px;
              .img{
                height: 100%;
                cursor: pointer;
              }
              .lbTitle{
                font-size: 1rem;
                color: #333;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
  .theme:hover{
    //border-bottom: 1px solid rgb(20, 146, 230);
    color: rgb(20, 146, 230);
  }
  .fixedBox{
    position: fixed;
    right: 0;
    top: 50%;
    //padding: 5px 0;
    border-radius: 5px;
    font-size: 1rem;
    color: #fff;
    cursor: pointer;
    z-index: 999;
    padding-top: 1.5rem;
    .iconBox{
      position: absolute;
      top: 0;
      right: 0;
      margin: 0 auto;
      display: inline-block;
      background: rgb(20, 146, 230);
      height: 1.5rem;
      line-height: 1.5rem;
      padding: 0 .5rem;
      border-radius: .2rem;
      transition: all 1s ease;
      .icon{
        //font-size: 2rem;
      }
    }
    .name{
      line-height: 2rem;
    }
    .ul1{
      position: relative;
      li{
        width: 7rem;
        //padding: 0 .5rem;
        line-height: 2rem;
        text-align: center;
        background: rgb(20, 146, 230);
        margin-top: .5rem;
        border-radius: 5px;
      }
      .icon{
        position: absolute;
        font-size: 40px;
        top: -45px;
        left: 50%;
        margin-left: -20px;
        color: red;
      }
    }

  }
  .v-enter-active,.v-leave-active {
    transition: all .8s ease;
  }
  .v-enter, .v-leave-to{
    transform: translateX(7rem);
    opacity: 0;
  }
  /deep/.el-image__error, .el-image__inner, .el-image__placeholder{
    width: auto!important;
  }
</style>
